<template>
  <div class="HonorManage">
    <div class="navC">
      <el-tabs v-model="form.search_achievement_type" @tab-click="handleClick">
        <el-tab-pane label="研究成果" name="1"></el-tab-pane>
        <el-tab-pane label="研究课题" name="2"></el-tab-pane>
      </el-tabs>
      <div class="two df dfr mt10">
        <el-button class="btn" @click="toPush(1)" v-if="form.search_achievement_type == 1">新增研究成果</el-button>
        <el-button class="btn" @click="toPush(2)" v-if="form.search_achievement_type == 2">新增研究课题</el-button>
      </div>
    </div>
    <div class="contentBox">
      <!-- 筛选 -->
      <div class="selectTop">
        <div class="lBox" v-if="form.search_achievement_type == 1">
          <el-select v-model="form.status" placeholder="请选择状态" class="sid" style="width: 150px">
            <el-option v-for="item in optiones" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>

          <el-select v-model="form.search_certificat_level" placeholder="成果级别" class="sid"
            style="width: 150px; margin-left: 10px">
            <el-option v-for="item in certificatList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>

          <el-select v-model="form.search_certificat_type" placeholder="成果类型" class="sid"
            style="width: 150px; margin-left: 10px">
            <el-option v-for="item in radioList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>

          <el-input v-model="form.search_name" placeholder="请输入成果标题" class="input"></el-input>

          <el-input v-model="form.username" placeholder="获奖/贡献/提交人姓名" class="input"></el-input>
          <el-button class="ml10" type="primary" @click="init()">查询</el-button>
          <el-button @click="clearAll()">重置</el-button>
        </div>
        <div class="lBox df dfb" v-if="form.search_achievement_type == 2">
          <div>
            <el-select v-model="form.status" placeholder="请选择状态" class="sid" style="width: 150px">
              <el-option v-for="item in optiones" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>

            <el-select v-model="form.search_certificat_level" placeholder="课题级别" class="sid"
              style="width: 150px; margin-left: 10px">
              <el-option v-for="item in certificatList" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
            <el-input v-model="form.search_name" placeholder="请输入课题标题" class="input"></el-input>

            <el-input v-model="form.username" placeholder="主持人/提交人姓名" class="input"></el-input>
            <el-button class="ml10" type="primary" @click="init()">查询</el-button>
            <el-button @click="clearAll()">重置</el-button>
          </div>
        </div>
      </div>
      <!-- 表格 -->
      <div class="tableList">
        <el-table :data="tableData" style="width: 1054px">
          <el-table-column prop="date" label="序号" align="center" width="70">
            <template slot-scope="scope">
              <span>{{ scope.$index + 1 }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="name" :label="form.search_achievement_type == 1 ? '成果标题' : '课题标题'" align="center" />
          <el-table-column prop="certificat_level_str" :label="form.search_achievement_type == 1 ? '成果级别' : '课题级别'"
            align="center" width="100" />
          <el-table-column prop="certificat_type_name" v-if="form.search_achievement_type == 1" label="成果类型"
            align="center" width="100" />
            <el-table-column prop="topic_num" v-if="form.search_achievement_type == 2" label="课题编号"
            align="center" width="100" />
          <el-table-column prop="usernames" :label="form.search_achievement_type == 1 ? '获奖人/贡献者' : '主持人'"
            align="center" width="120" />
          <el-table-column prop="user_id_name" label="提交人" align="center" width="120" />
          <el-table-column prop="create_time" label="提交时间" sortable align="center" width="180" />
          <el-table-column prop="title_status" label="状态" align="center" width="100" />
          <el-table-column prop="date" label="操作" align="center" width="150">
            <template slot-scope="scope">
              <el-button type="text" @click="toArticleDetail(scope.row.id)">查看</el-button>
              <el-button type="text" @click="toArticleEdit(form.search_achievement_type, scope.row.id)">编辑</el-button>
              <el-button type="text" @click="toArticleDetail(scope.row.id)"
                v-if="scope.row.title_status == '待审核'">审核</el-button>
              <el-button type="text" @click="onRemove(scope.row.id)" v-if="scope.row.title_status != '待审核'">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="block" v-show="total">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page.sync="form.page" :page-size="form.limit" layout="prev, pager, next,jumper" :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { DelCertificat } from "@/api/teacherStudio";
import { CertificateType } from "@/api/teacherStudio/index.js";
export default {
  data() {
    return {
      textarea: "",
      dialogVisible: false,
      dialogYulan: false,
      optiones: [
        {
          id: 1,
          name: "待审核",
        },
        {
          id: 2,
          name: "展示中",
        },
        {
          id: 3,
          name: "未通过",
        },
      ],

      //表格数据
      tableData: [],
      //总数
      total: 0,
      //表单数据
      form: {
        search_name: "",
        page: 1,
        limit: 10,
        certificat_type: "",
        status: "",
        username: "",
        search_achievement_type: '1',
        teaching_studio_id: localStorage.getItem("studioId"),
        search_certificat_level: "",
        search_certificat_type: "",
      },
      certificatList: [
        { id: 1, name: "国家级" },
        { id: 2, name: "省级" },
        { id: 3, name: "市级" },
        { id: 4, name: "区县级" },
        { id: 5, name: "校级" },
        { id: 6, name: "其他" },
      ],
      radioList: [],
      //审核表单
      form1: {
        status: "", //2 审核通过 3审核不通过
        teaching_studio_id: localStorage.getItem("studioId"),
        id: "", //审核id
      },
      InfoObj: {},
    };
  },
  created() {
    let rouName = this.$route.name
    this.$emit('rouname', rouName)
    //初始化
    this.init();
  },
  computed: {},
  methods: {
    toPush(val) {
      this.$router.push({
        path: "/AddCertificateSet",
        query: {
          achievement_type: val
        }
      })
    },
    //跳转文章详情
    toArticleDetail(tid) {
      this.$router.push({
        path: "/CertificateDetail",
        query: {
          id: tid,
          is_from: 1
        },
      });
    },
    //获取成果证书类型
    getCertificatesType() {
      CertificateType({ teaching_studio_id: localStorage.getItem("studioId") }).then((res) => {
        this.radioList = res.data.data || [];
      });
    },
    handleClick(val) {
      this.form = {
        search_name: "",
        page: 1,
        limit: 10,
        certificat_type: "",
        status: "",
        username: "",
        search_achievement_type: this.form.search_achievement_type,
        teaching_studio_id: localStorage.getItem("studioId"),
      }
      this.tableData = []
      this.init();
    },
    yulan(row) {
      let obj = {
        id: row.id,
        teaching_studio_id: localStorage.getItem("studioId"),
      };
      this.$axios.get("TeachingStudioCertificate/get_info", { params: obj, }).then((res) => {
        this.InfoObj = res.data.data;
      });
    },
    //初始
    init() {
      this.getCertificatesType()
      this.CertificateData();
    },
    CertificateData() {
      this.$axios
        .get("TeachingStudioCertificate/lists", {
          params: this.form,
        })
        .then((res) => {
          this.tableData = res.data.data.data;
          this.total = res.data.data.total;
          console.log(res, "初始化数据");
        });
    },

    //不通过
    noPass(id) {
      this.form1.id = id; //审核id
      this.dialogVisible = true;
    },
    // 删除
    onRemove(id) {
      this.myConfirm({
        content: `确定要删除当前数据吗？`,
        fn: this.onDelApi,
        id,
      });
    },
    // 删除接口
    onDelApi(id) {
      DelCertificat({
        id,
        teaching_studio_id: localStorage.getItem("studioId"),
      }).then((res) => {
        this.$message({
          showClose: true,
          message: "删除成功",
          type: "success",
        });
        this.CertificateData();
      });
    },

    //通过
    PassWay(id, type) {
      this.form1.status = type; //2 审核通过 3审核不通过
      this.form1.id = id; //审核id
      this.$axios
        .put("TeachingStudioCertificate/status", this.form1)
        .then(() => {
          if (type == 2) {
            this.$message.success("审核通过");
          } else {
            this.$message.success("审核不通过");
            this.dialogVisible = false;
          }

          //初始化
          this.CertificateData();
        });
    },

    //重置
    clearAll() {
      this.form = {
        search_name: "",
        page: 1,
        limit: 10,
        certificat_type: "",
        status: "",
        username: "",
        search_achievement_type: this.form.search_achievement_type,
        teaching_studio_id: localStorage.getItem("studioId"),
        search_certificat_level: "",
        search_certificat_type: "",
      }
      //初始化
      this.CertificateData();
    },
    handleSizeChange(val) {
      this.form.limit = val;
      this.CertificateData();
    },
    handleCurrentChange(val) {
      this.form.page = val;
      this.CertificateData();
    },
    toArticleEdit(val, id) {
      this.$router.push({
        path: "/AddCertificateSet",
        query: {
          achievement_type: val,
          id
        }
      })
    }
  },
};
</script>
<style lang="less" scoped>
.HonorManage {
  .navC {
    width: 100%;
    height: 60px;
    padding-left: 26px;
    display: flex;
    align-items: end;
    border-bottom: 1px solid #f0f0f0;
    justify-content: space-between;
    box-sizing: border-box;

    /deep/.el-tabs__header {
      margin: 0;

      .el-tabs__item {
        font-size: 16px;
        margin-bottom: 8px;
      }

      .el-tabs__item.is-active {
        font-weight: bold;
      }
    }

    .two {
      margin-right: 20px;
      margin-bottom: 10px;

      .btn {
        background: #ff8201;
        border-radius: 4px;
        color: #fff;
        border: 1px solid #ff8201;
      }
    }
  }


  //内容盒子
  .contentBox {
    padding: 26px 26px;

    .selectTop {
      display: flex;

      .lBox {
        width: 100%;

        .input {
          width: 186px;
          margin-left: 10px;
        }
      }


    }



    //表格
    .tableList {
      width: 1054px;
      margin-top: 24px;
      border: 1px solid #ebeef5;
      border-bottom: none;

      span {
        margin-right: 30px;
        color: #3489fe;
        cursor: pointer;
      }
    }

    //样式
    .block {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
    }
  }
}
</style>
